import React from 'react'
import Footer from '../Footer'
import ContactSectionnew from '../Formcontact'
import Header from '../Header'

const Contact = () => {
  return (
    <div className='contact-page'>
        <Header/>
        <ContactSectionnew/>
        <Footer />
      
    </div>
  )
}

export default Contact
